import { useDappWallet } from "common/hooks/dapp";
import { Box } from "components/base";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const WalletConnectPage = () => {
  const { connect, account } = useDappWallet();
  const navigate = useNavigate();
  useEffect(() => {
    if (account) navigate("home");
  });
  return (
    <Box
      flex={1}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      color={"white"}
    >
      <Box
        fontSize={["35px", "45px", "60px", "70px"]}
        fontWeight={"600"}
        lineHeight={["45px", "60px", "70px", "80px"]}
      >
        Welcome to UNCOM
      </Box>
      <Box
        mt={"12px"}
        fontSize={["22px", "24px", "32px", "38px"]}
        fontWeight={"600"}
        lineHeight={["26px", "30px", "38px", "46px"]}
        textAlign={"center"}
      >
        Please connect your wallet to view
        <br />
        your account.
      </Box>
      <WalletConnectBtn
        mt={"68px"}
        background={"#1726354D"}
        padding={["20px 44px", "22px 52px", "26px 60px", "30px 68px"]}
        border={"5px solid #0ED1E8"}
        display={"inline-flex"}
        borderRadius={"20px"}
        color={"#0ED1E8"}
        cursor={"pointer"}
        fontSize={["18px", "21px", "28px", "38px"]}
        fontWeight={"500"}
        lineHeight={["18px", "21px", "28px", "38px"]}
        boxShadow={"0px 0px 21px 3px rgba(14, 209, 232, 0.75)"}
        onClick={() => {
          connect();
        }}
      >
        Connect
      </WalletConnectBtn>
    </Box>
  );
};

const WalletConnectBtn = styled(Box)`
  position: relative;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    /* background: rgba(14, 209, 232, 0.75); */
    border: 2px solid rgba(14, 209, 232, 0.75);
    /* filter: blur(2px); */
    width: 100%;
    border-radius: 20px;
    height: 100%;
    z-index: -1;
    transform: translate(-50%, -50%);
    animation: ${keyframes`
      0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0;
      }
    `} 3s infinite linear;
  }
  &::after {
    animation-delay: 0.7s;
  }
`;

export default WalletConnectPage;
