import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

interface UncomState {
  uncomPriceByTFuel: number;
}

const initialState = {
  uncomPriceByTFuel: 0,
} as UncomState;

export const uncomSlice = createSlice({
  name: "uncom",
  initialState,
  reducers: {
    setUncomPriceByTFuel: (state: UncomState, action: PayloadAction<number>) => {
      state.uncomPriceByTFuel = action.payload;
    },
  },
});

export const { setUncomPriceByTFuel } = uncomSlice.actions;
// export const selectCount = (state: RootState) => state.uncom.value;
export default uncomSlice.reducer;
