import { createContext } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

const DappProvider: React.FC = ({ children }) => {
  const getLibrary = (provider: any) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
  };
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <DappContextProvider>{children}</DappContextProvider>
    </Web3ReactProvider>
  );
};

export const DappContext = createContext<any>(null);

const DappContextProvider: React.FC = ({ children }) => {
  return <DappContext.Provider value={{}}>{children}</DappContext.Provider>;
};

export default DappProvider;
