export const ArrowIcon: React.FC<{
  size?: string;
  color?: string;
  dir?: "up" | "down" | "left" | "right";
}> = ({ size = "1em", color = "currentColor", dir = "up" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${
          dir === "up" ? 0 : dir === "down" ? 180 : dir === "left" ? -90 : 90
        }deg)`,
      }}
    >
      <path
        d="M11.2773 5.33214L5.99771 0.5L0.7181 5.33214C0.574673 5.46385 0.496269 5.64055 0.500137 5.82339C0.504004 6.00623 0.589826 6.18022 0.738723 6.30708C0.88762 6.43395 1.0874 6.5033 1.2941 6.49988C1.5008 6.49646 1.69751 6.42054 1.84093 6.28884L5.99771 2.48231L10.1591 6.28884C10.3025 6.42054 10.4992 6.49646 10.7059 6.49988C10.9126 6.5033 11.1124 6.43395 11.2613 6.30708C11.4102 6.18022 11.496 6.00623 11.4999 5.82339C11.5037 5.64055 11.4253 5.46385 11.2819 5.33214H11.2773Z"
        fill={color}
      />
    </svg>
  );
};
export const ListIcon: React.FC<{
  size?: string;
  color?: string;
  dir?: "up" | "down" | "left" | "right";
}> = ({ size = "1em", color = "currentColor", dir = "up" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3H37.125M3 29H37.125H3ZM3 16H37.125H3Z"
        stroke={color}
        strokeWidth="4.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TimesIcon: React.FC<{
  size?: string;
  color?: string;
  dir?: "up" | "down" | "left" | "right";
}> = ({ size = "1em", color = "currentColor", dir = "up" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 2L2 28M27 28L2 2L27 28Z"
        stroke={color}
        strokeWidth="3.625"
        strokeLinecap="round"
      />
    </svg>
  );
};
