import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "./store.slice.user";
import uncomReducer from "./store.slice.uncom";

const store = configureStore({
  reducer: {
    userInfor: userReducer,
    uncomInfor: uncomReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
