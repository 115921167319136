export const UNCOM = {
  address: "0x6087fd1e0d47fee51549762fc735dcbbc4aa2cc8",
  abi: require("./abis/uncom.json"),
};

export const WTFUEL = {
  address: "0x4dc08b15ea0e10b96c41aec22fab934ba15c983e",
  abi: require("./abis/wtfuel.json"),
};

export const ThetaSwapV2Factory = {
  address: "0xe8b97478ae8ab1fcfd46cdb2f62869ec63bbf69f",
  abi: require("./abis/thetaswapv2factory.json"),
};

export const ThetaSwapV2Router = {
  address: "0x8366537d56cf2b86ca90e9dbc89450207a29f6e3",
  abi: require("./abis/thetaswapv2router.json"),
};