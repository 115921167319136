import styled from "styled-components";
import { Box } from "./initial";

const FlexBox = styled(Box).attrs({
  display: "flex",
})``;
export const ContainerFluid = styled(Box)``;
ContainerFluid.defaultProps = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
};
export const Container = styled(Box)``;
Container.defaultProps = {
  width: "100%",
  maxWidth: "1440px",
  px: [3],
};
export const ContainerMin = styled(Box)``;
ContainerMin.defaultProps = {
  width: "100%",
  maxWidth: "800px",
  px: [3],
};
