import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { ThetaSwapV2Factory, UNCOM, WTFUEL } from "config";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store.hook";
import { setUncomPriceByTFuel } from "store/store.slice.uncom";
import { setBalance } from "store/store.slice.user";
import Web3 from "web3";

export const useDappWallet = () => {
  const [web3, setWeb3] = useState<Web3>();
  const dispatch = useAppDispatch();
  const { activate, deactivate, connector, library, chainId, account, active, error } = useWeb3React();
  const connect = () => {
    activate(injected);
    window.localStorage.setItem("walletConnected", "connected");
  };
  const disconnect = () => {
    deactivate();
    window.localStorage.removeItem("walletConnected");
  };

  useEffect(() => {
    if (window.localStorage.getItem("walletConnected") && !account) connect();
  }, []);

  useEffect(() => {
    if (!account || web3) return;
    setWeb3(new Web3(library.provider));
  }, [account, web3]);
  useEffect(() => {
    if (!web3) return;
    getBalance(UNCOM, account, web3, dispatch);
    getSwapRate(ThetaSwapV2Factory, UNCOM, WTFUEL, account, web3, dispatch);
  }, [account, web3]);
  return { connect, disconnect, account, chainId, connector, library, active, error, web3 };
};

const getBalance = async (Token: any, account: any, web3: any, dispatch: any) => {
  const contract = await new web3.eth.Contract(UNCOM.abi, UNCOM.address);
  const balance = await contract.methods.balanceOf(account).call();
  dispatch(setBalance(balance));
};

const getSwapRate = async (Factory: any, tokenInput: any, tokenOutput: any, account: any, web3: any, dispatch: any) => {
  const contractFactory = await new web3.eth.Contract(Factory.abi, Factory.address);
  const contractTokenInput = await new web3.eth.Contract(tokenInput.abi, tokenInput.address);
  const contractTokenOutput = await new web3.eth.Contract(tokenOutput.abi, tokenOutput.address);
  const pair = await contractFactory.methods.getPair(tokenInput.address, tokenOutput.address).call();
  const tokenInputBalance = await contractTokenInput.methods.balanceOf(pair).call();
  const tokenOutputBalance = await contractTokenOutput.methods.balanceOf(pair).call();
  dispatch(setUncomPriceByTFuel(tokenOutputBalance / tokenInputBalance));
};

const injected = new InjectedConnector({
  supportedChainIds: [361],
});
