import { useDappWallet } from "common/hooks/dapp";
import { Box, Image } from "components/base";
import { ArrowIcon } from "components/icon";
import { useState } from "react";
import { useAppSelector } from "store/store.hook";
import styled, { keyframes } from "styled-components";

const BuyPage = () => {
  const { account } = useDappWallet();
  const uncomPriceByTFuel = useAppSelector((state) => state.uncomInfor.uncomPriceByTFuel);
  const balanceUNCOM = useAppSelector((store) => store.userInfor.balance);
  return (
    <Box
      flex={1}
      px={["10px", "30px", "70px", "110px", "150px"]}
      display={"flex"}
      flexDirection={"column"}
      overflow={"auto"}
    >
      {account && (
        <Box
          padding={"8px 16px"}
          border={"2px solid rgba(14, 209, 232, 0.5)"}
          borderRadius={"9px"}
          color={"#0ED1E8"}
          display={["flex", "flex", "none", "none"]}
          flexDirection={"column"}
          gridGap={"8px"}
          zIndex={"1"}
          backDrop={"blur(20px)"}
        >
          <Box display={"flex"} gridGap={"16px"}>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              Account:
            </Box>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              {`${account.slice(0, 6)}...${account.slice(-4)}`}
            </Box>
          </Box>
          <Box display={"flex"} gridGap={"16px"}>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              Last Price:
            </Box>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              {`${uncomPriceByTFuel.toString().slice(0, 6)}... TFUEL`}
            </Box>
          </Box>
          <Box display={"flex"} gridGap={"16px"}>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              My Balance:
            </Box>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              {`${balanceUNCOM} UNCOM`}
            </Box>
          </Box>
          <Box display={"flex"} gridGap={"16px"}>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              Current Rank:
            </Box>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              {balanceUNCOM < 50
                ? "None"
                : balanceUNCOM < 500
                ? "Bronze"
                : balanceUNCOM < 5000
                ? "Silver"
                : "Gold"}
            </Box>
          </Box>
        </Box>
      )}
      <Box
        pt={["20px", "35px", "50px", "65px"]}
        fontWeight={"600"}
        fontSize={["20px", "24px", "28px", "32px"]}
        lineHeight={"39px"}
        color={"#FFFFFF"}
        textShadow={"0px 0px 10px rgba(0, 255, 241, 0.26)"}
      >
        Welcome, your current balance is {balanceUNCOM} UNCOM,{" "}
        {balanceUNCOM < 50 ? (
          <>you have no rank</>
        ) : (
          <>
            you are{" "}
            <Box
              display={"inline-block"}
              color={balanceUNCOM < 500 ? "#CD7F32" : balanceUNCOM < 5000 ? "#C0C0C0" : "#FFD700"}
              uppercase
            >
              {balanceUNCOM < 500 ? "Bronze" : balanceUNCOM < 5000 ? "Silver" : "Gold"}
            </Box>{" "}
            rank
          </>
        )}
      </Box>
      <Box py={["20px", "35px", "50px", "65px"]}>
        <LvlView balance={balanceUNCOM} />
      </Box>
    </Box>
  );
};

const LvlView: React.FC<{ balance: number }> = ({ balance }) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      gridGap={"32px"}
    >
      <UserLvlCard lvl="Bronze" balance={balance}>
        <Box>VIP airdrops of NFTs, UNCOM, and other tokens</Box>
        <Box>VIP Badge differentiating your UNCOM profile from regular users</Box>
      </UserLvlCard>
      <UserLvlCard lvl="Silver" balance={balance}>
        <Box>All Bronze Tier benefits, plus:</Box>
        <Box>
          Early access to all the newest UNCOM Events, Courses, Projects. If there is a waitlist,
          you will be placed ahead of all Bronze Tier users and users without TDROP
        </Box>
      </UserLvlCard>
      <UserLvlCard lvl="Gold" balance={balance}>
        <Box>All Bronze and Silver Tier benefits, plus:</Box>
        <Box>
          Exclusive access to certain content {"&"} events — the only way to access certain events
          will be via the UNCOM Gold Tier.
        </Box>
        <Box>
          Your courses, services etc will be listed preferentially in the UNCOM marketplace.
        </Box>
      </UserLvlCard>
    </Box>
  );
};

const UserLvlCard: React.FC<{
  lvl?: "Bronze" | "Silver" | "Gold";
  balance?: number;
  children?: any;
}> = ({ lvl = "Gold", balance = 1000, children }) => {
  const lvlStep = {
    Bronze: { min: 0, max: 50 },
    Silver: { min: 50, max: 500 },
    Gold: { min: 500, max: 5000 },
  };
  const [visibleBenefits, setVisibleBenefits] = useState(false);
  const lvlColor = lvl === "Bronze" ? "#CD7F32" : lvl === "Silver" ? "#C0C0C0" : "#FFD700";
  return (
    <Box
      boxSizing={"border-box"}
      background={"#172635"}
      padding={["22px 20px", "26px 24px", "26px 34px", "26px 46px"]}
      border={"5px solid #3B5D78"}
      boxShadow={
        balance >= lvlStep[lvl].max && (balance < lvlStep[lvl].max * 10 || lvl === "Gold")
          ? "0px 0px 21px 3px rgba(205, 127, 50, 0.51)"
          : "none"
      }
      borderRadius={"20px"}
    >
      <Box
        fontSize={["20px", "24px", "32px", "42px"]}
        fontWeight={"600"}
        lineHeight={"33px"}
        color={lvlColor}
        uppercase
      >
        {lvl}
      </Box>
      <Box
        mt={"17px"}
        fontSize={["14px", "16px", "18px", "21px"]}
        fontWeight={"400"}
        lineHeight={"33px"}
        color={balance >= lvlStep[lvl].min ? lvlColor : `${lvlColor}30`}
      >
        {balance > lvlStep[lvl].max ? lvlStep[lvl].max : balance}/{lvlStep[lvl].max} UNCOM TOKENS
      </Box>
      <Box
        mt={"6px"}
        display={"flex"}
        alignItems={"center"}
        gridGap={["12px", "12px", "25px", "37px", "45px"]}
      >
        <Box flex={1}>
          <Box
            background={`${lvlColor}30`}
            // background={balance >= lvlStep[lvl].min ? `${lvlColor}30` : `${lvlColor}10`}
            width={
              lvl === "Bronze"
                ? ["100%", "40%", "40%", "40%"]
                : lvl === "Silver"
                ? ["100%", "70%", "70%", "70%"]
                : ["100%", "100%", "100%", "100%"]
            }
            height={"12px"}
            borderRadius={"100px"}
            overflow="hidden"
          >
            <Box
              background={lvlColor}
              // background={`${balance >= lvlStep[lvl].min ? lvlColor : `${lvlColor}30`}`}
              // width={`${
              //   balance >= lvlStep[lvl].max
              //     ? 100
              //     : balance <= lvlStep[lvl].min
              //     ? 0
              //     : ((balance - lvlStep[lvl].min) / (lvlStep[lvl].max - lvlStep[lvl].min)) * 100
              // }%`}
              width={`${balance >= lvlStep[lvl].max ? 100 : (balance / lvlStep[lvl].max) * 100}%`}
              height={"12px"}
              borderRadius={"100px"}
            ></Box>
          </Box>
        </Box>
        <Box
          fontSize={["10px", "12px", "14px", "18px", "21px"]}
          fontWeight={"400"}
          lineHeight={"1em"}
          color={lvlColor}
          visible={visibleBenefits ? "hidden" : "visible"}
          display={"flex"}
          alignItems={"center"}
          gridGap={"4px"}
          cursor={"pointer"}
          onClick={() => setVisibleBenefits(true)}
        >
          View {lvl} benefits
          <Box display={"inline-flex"} alignItems={"center"} fontSize={["5px", "8px", "12px"]}>
            <ArrowIcon dir="right" />
          </Box>
        </Box>
      </Box>
      <Box display={visibleBenefits ? "flex" : "none"}>
        <Box
          pt={"26px"}
          flex={"1"}
          fontSize={["14px", "16px", "18px", "21px"]}
          fontWeight={"400"}
          lineHeight={["18px", "19px", "24px", "28px", "33px"]}
          color={"white"}
        >
          <Box>
            As a {lvl} ranked UNCOM member, you {balance < lvlStep[lvl].max && "will "}receive:
          </Box>
          <Box
            mt={"21px"}
            width={["100%", "100%", "100%", "80%", "70%"]}
            display={"flex"}
            flexDirection={"column"}
            gridGap={["4px", "6px", "8px", "12px"]}
          >
            {children.map((each: any, index: any) => {
              return (
                <Box
                  key={index}
                  pl={["10 px", "12px", "15px"]}
                  display={"flex"}
                  alignItems={"flex-start"}
                  gridGap={["4px", "6px", "8px"]}
                >
                  <Box fontSize={["20px", "25px", "30px"]}>
                    <Image src={require("assets/image/mini_logo.png")} width={"1em"} />
                  </Box>
                  {each}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          width={"250px"}
          paddingTop={"50px"}
          paddingBottom={"100px"}
          display={["none", "none", "flex"]}
          justifyContent={"center"}
          alignItems={"center"}
          opacity={balance >= lvlStep[lvl].min ? "1" : "0.3"}
        >
          <LvlItem lvl={lvl} active={balance >= lvlStep[lvl].max} />
        </Box>
      </Box>
      <Box mt={["5px", "8px", "12px"]} display={visibleBenefits ? "flex" : "none"}>
        <Box
          ml={"auto"}
          fontSize={["10px", "12px", "14px", "18px", "21px"]}
          fontWeight={"400"}
          lineHeight={"1em"}
          color={lvlColor}
          display={"flex"}
          alignItems={"center"}
          gridGap={"4px"}
          cursor={"pointer"}
          onClick={() => setVisibleBenefits(false)}
        >
          Hide {lvl} benefits
          <Box display={"inline-flex"} alignItems={"center"} fontSize={["5px", "8px", "12px"]}>
            <ArrowIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const LvlItem: React.FC<{ height?: number; lvl: string; active: boolean }> = ({
  height = 15,
  lvl,
  active,
}) => {
  return (
    <LvlItemContainer layerCount={height}>
      <LvlItemBox position={"relative"} active={active}>
        {Array(height)
          .fill(height)
          .map((each, index) => {
            return index === 0 ? (
              <LvlItemLayer
                key={index}
                index={index}
                total={each}
                fontSize={"32px"}
                lvlColor={lvl === "Bronze" ? "#CD7F32" : lvl === "Silver" ? "#C0C0C0" : "#FFD700"}
              >
                <Image
                  src={require("assets/image/logo.png")}
                  width={"50px"}
                  style={{ mixBlendMode: "luminosity" }}
                />
                {lvl}
              </LvlItemLayer>
            ) : (
              <LvlItemLayer
                key={index}
                index={index}
                total={each}
                lvlColor={lvl === "Bronze" ? "#CD7F32" : lvl === "Silver" ? "#C0C0C0" : "#FFD700"}
              />
            );
          })}
      </LvlItemBox>
    </LvlItemContainer>
  );
};

const LvlItemContainer = styled(Box)<{ layerCount: number }>`
  perspective: 500px;
  transform-style: preserve-3d;
`;

const LvlItemBox = styled(Box)<{ active: boolean }>`
  transform-style: preserve-3d;
  animation: ${keyframes`
      0% {
        transform: rotateX(60deg) rotateZ(70deg);
      }
      100% {
        transform: rotateX(60deg) rotateZ(430deg);
      }
    `} 15s infinite linear;
  animation-play-state: ${(p) => (p.active ? "play" : "paused")};
`;

const LvlItemLayer = styled(Box)<{
  total: number;
  index: number;
  lvlColor: string;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  background: ${(p) => `${p.lvlColor}f0`};
  width: 250px;
  height: 100px;
  transform-style: preserve-3d;
  text-transform: uppercase;
  font-weight: 700;
  color: #00000044;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  &:nth-of-type(${(p) => p.index + 1}) {
    transform: translate(-50%, -50%) translateZ(${(p) => (p.total / 2 - p.index) * 2}px)
      scale(${(p) => 0.85 + (p.index / p.total) * 0.15});
  }
  &:nth-of-type(${(p) => p.index + 1}) {
    border-radius: 10px;
    box-shadow: 0 0 5px 0px black;
  }
`;

export default BuyPage;
