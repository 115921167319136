import { Box } from "components/base";
import { useDappWallet } from "common/hooks/dapp";
import { useAppSelector } from "store/store.hook";
import styled from "styled-components";

const WalletConnectBtn: React.FC<{ [index: string]: any }> = ({ hideInfor = false, ...props }) => {
  const { connect, disconnect, account } = useDappWallet();
  const uncomPriceByTFuel = useAppSelector((store) => store.uncomInfor.uncomPriceByTFuel);
  const balanceUNCOM = useAppSelector((store) => store.userInfor.balance);
  return (
    <WalletConnectBtnCont connected={account}>
      <Box
        padding={"8px 16px"}
        border={"2px solid rgba(14, 209, 232, 0.5)"}
        display={"inline-flex"}
        borderRadius={"9px"}
        color={"#0ED1E8"}
        cursor={"pointer"}
        {...props}
        onClick={() => {
          account ? disconnect() : connect();
        }}
      >
        {account ? `${account.slice(0, 6)}...${account.slice(-4)}` : "Connect"}
      </Box>
      {account && !hideInfor && (
        <Box
          position={"absolute"}
          right={"0px"}
          width={"250px"}
          padding={"8px 16px"}
          border={"2px solid rgba(14, 209, 232, 0.5)"}
          borderRadius={"9px"}
          color={"#0ED1E8"}
          display={"flex"}
          flexDirection={"column"}
          gridGap={"8px"}
          visible={"hidden"}
          zIndex={"1"}
          backDrop={"blur(20px)"}
        >
          <Box display={"flex"} gridGap={"16px"}>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              Account:
            </Box>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              {`${account.slice(0, 6)}...${account.slice(-4)}`}
            </Box>
          </Box>
          <Box display={"flex"} gridGap={"16px"}>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              Last Price:
            </Box>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              {`${uncomPriceByTFuel.toString().slice(0, 6)}... TFUEL`}
            </Box>
          </Box>
          <Box display={"flex"} gridGap={"16px"}>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              My Balance:
            </Box>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              {`${balanceUNCOM} UNCOM`}
            </Box>
          </Box>
          <Box display={"flex"} gridGap={"16px"}>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              Current Rank:
            </Box>
            <Box flex={"1"} whiteSpace={"nowrap"}>
              {balanceUNCOM < 50
                ? "None"
                : balanceUNCOM < 500
                ? "Bronze"
                : balanceUNCOM < 5000
                ? "Silver"
                : "Gold"}
            </Box>
          </Box>
        </Box>
      )}
    </WalletConnectBtnCont>
  );
};

const WalletConnectBtnCont = styled(Box)<{ connected: any }>`
  z-index: 2;
  & > div:first-child:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(p) => (p.connected ? "red" : "")};
    color: ${(p) => (p.connected ? "red" : "")};
    border-color: ${(p) => (p.connected ? "white" : "")};
    &::after {
      content: "Disconnect";
      display: ${(p) => (p.connected ? "flex" : "none")};
      background: red;
      color: white;
      position: absolute;
    }
  }
  & > div:first-child ~ div {
    top: 200%;
    visibility: visible;
    opacity: 0;
    transition: 500ms;
  }
  & > div:first-child:hover ~ div {
    top: 120%;
    visibility: visible;
    opacity: 1;
  }
`;

WalletConnectBtnCont.defaultProps = {
  position: "relative",
};

export default WalletConnectBtn;
