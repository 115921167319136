import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TOKEN_DECIMAL } from "common/constant";
import type { RootState } from "store";

interface UserState {
  balance: number;
}

const initialState = {
  balance: 0,
} as UserState;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setBalance: (state: UserState, action: PayloadAction<number>) => {
      state.balance = Math.round(action.payload / Math.pow(10, TOKEN_DECIMAL - 3)) / 1000;
    },
  },
});

export const { setBalance } = userSlice.actions;
// export const selectCount = (state: RootState) => state.user.value;
export default userSlice.reducer;
