import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "components/layout";
import BuyPage from "pages/home";

import "./App.css";
import WalletConnectPage from "pages/walletConnect";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path={""} element={<WalletConnectPage />} />
          <Route path={"home"} element={<BuyPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
