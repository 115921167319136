import { useDappWallet } from "common/hooks/dapp";
import { Box, Image, Link } from "components/base";
import WalletConnectBtn from "components/functionalElements";
import { ListIcon, TimesIcon } from "components/icon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const links = [
    { name: "Wallet", target: "", isInnerLink: true },
    { name: "Uncom App", target: "https://app.uncom.io/" },
    {
      name: "Buy Uncom",
      target:
        "https://thetaswap.io/swap?tokenAddressInput=0x4dc08b15ea0e10b96c41aec22fab934ba15c983e&tokenAddressOutput=0x6087fd1e0d47fee51549762fc735dcbbc4aa2cc8",
    },
    { name: "Socials", target: "https://linktr.ee/theatgmentor" },
    { name: "Join", target: "https://uncommonsuccess.com/join-use" },
  ];
  const navigate = useNavigate();
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const { account } = useDappWallet();
  return (
    <>
      <Box
        position={"fixed"}
        top={"0px"}
        left={sideBarVisible ? "0px" : "-100%"}
        background={"#000000a2"}
        backDrop={"blur(10px)"}
        width={"330px"}
        height={"100vh"}
        py={"42px"}
        px={"34px"}
        boxSizing={"border-box"}
        borderRight={"2px solid #0ED1E8"}
        zIndex={"11111"}
        transition={sideBarVisible ? "1000ms" : "1000ms"}
        display={"flex"}
        flexDirection={"column"}
        onMouseLeave={() => setSideBarVisible(false)}
      >
        <Box fontSize={"26px"} color={"#0ED1E8"} cursor={"pointer"} display={"inline-flex"}>
          <Box onClick={() => setSideBarVisible(false)}>
            <TimesIcon />
          </Box>
        </Box>
        <Box mt={"44px"} display={"flex"} flexDirection={"column"}>
          {links.map((each: any, index: any) => {
            return (
              <Box
                key={index}
                fontSize={"22px"}
                fontWeight={"800"}
                lineHeight={"50px"}
                color={"white"}
                cursor={"pointer"}
                onClick={() => {
                  !each.isInnerLink ? window.open(each.target, "_blank") : navigate(each.target);
                }}
              >
                {each.name}
              </Box>
            );
          })}
        </Box>
        <Box mt={"auto"} display={"flex"} justifyContent={"center"}>
          <WalletConnectBtn hideInfor />
        </Box>
      </Box>
      <Box
        px={["20px", "25px", "30px", "45px"]}
        py={"17px"}
        display={"flex"}
        justifyContent={["center", "center", "space-between", "space-between"]}
        alignItems={"center"}
        gridGap={["16px", "20px", "28px", "36px"]}
      >
        <Box
          position={"absolute"}
          left={"25px"}
          display={["flex", "flex", "none", "none"]}
          fontSize={"34px"}
          color={"#0ED1E8"}
          cursor={"pointer"}
          onClick={() => setSideBarVisible(!sideBarVisible)}
        >
          <ListIcon />
        </Box>
        <Image
          src={require("assets/image/logo.png")}
          width={"74px"}
          cursor={"pointer"}
          onClick={() => {
            navigate("/");
          }}
        />
        <Box
          mr={"auto"}
          display={["none", "none", "flex", "flex"]}
          alignItems={"center"}
          gridGap={["16px", "20px", "28px", "36px"]}
        >
          {links.map((each: any, index: any) => {
            return (
              <HeaderLink
                key={index}
                active={index === 0}
                target={each.target}
                isOuterLink={!each.isInnerLink}
              >
                {each.name}
              </HeaderLink>
            );
          })}
        </Box>
        <Box display={["none", "none", "flex", "flex"]} alignItems={"center"}>
          <WalletConnectBtn />
        </Box>
      </Box>
    </>
  );
};

const HeaderLink: React.FC<{
  active?: boolean;
  isOuterLink?: boolean;
  target?: any;
  children?: any;
}> = ({ active, isOuterLink, target, children }) => {
  const navigate = useNavigate();
  return (
    <Link
      onClick={() => {
        isOuterLink ? window.open(target, "_blank") : navigate(target);
      }}
      fontSize={["16px", "18px", "20px", "22px"]}
      fontWeight={active ? "bold" : "unset"}
      color={"#0ED1E8"}
      textShadow={"0px 0px 10px rgba(0, 255, 241, 0.26)"}
    >
      {children}
    </Link>
  );
};

export default Header;
