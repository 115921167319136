import { Box } from "components/base";
import Header from "./layout.header";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDappWallet } from "common/hooks/dapp";

const Layout: React.FC = ({ children }) => {
  const [videoOpacityIs1, setVideoOpacityIs1] = useState(true);
  const [videoVisible, setVideoVisible] = useState(true);
  const { account } = useDappWallet();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!account && location.pathname !== "/") navigate("/", { replace: true });
  });
  useEffect(() => {
    setTimeout(() => {
      setVideoOpacityIs1(false);
    }, 4000);
  }, []);
  return (
    <>
      <Box height={"100%"} display={"flex"} flexDirection={"column"}>
        <Header />
        {children}
      </Box>
      <Box
        position={"fixed"}
        top={"0px"}
        left={"0px"}
        bg={"black"}
        width={"100vw"}
        height={"100vh"}
        zIndex={111}
        opacity={videoOpacityIs1 ? "1" : "0"}
        display={videoVisible ? "flex" : "none"}
        transition={"1s"}
        onTransitionEnd={(e) => setVideoVisible(false)}
        onClick={() => {
          setVideoVisible(false);
        }}
      >
        <VideoBG loop autoPlay playsInline muted>
          <source
            src={require("assets/video/Uncom logo sting for website dark.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </VideoBG>
      </Box>
    </>
  );
};

const VideoBG = styled.video`
  min-width: 100vw;
  min-height: 100vh;
  object-fit: cover;
`;

export default Layout;
