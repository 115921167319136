import React from "react";
import ThemesProvider from "./provider.theme";
import DappProvider from "./provider.dapp";
import StoreProvider from "./provider.store";

const AllProvider: React.FC = ({ children }) => {
  return (
    <StoreProvider>
      <ThemesProvider>
        <DappProvider>{children}</DappProvider>
      </ThemesProvider>
    </StoreProvider>
  );
};

export default AllProvider;
